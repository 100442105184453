<template>
  <div>
    <v-toolbar
      color="transparent"
      class="mt-n10 d-flex justify-center mb-n4"
      flat
    >
      <strong>Payment Method</strong>
    </v-toolbar>
    <v-row justify="center">
      <v-col class="pt-0" cols="12" md="5">
        <small class="grey--text">
          Want to update the Credit Card we have on file, please provide the
          details here. And don't worry, your card information will never
          directly touch our servers.
        </small>
        <v-card class="rounded-xl pa-6" flat>
          <v-form class="text-left" lazy-validation ref="form">
            <label class="capitalize text-12">card holder name</label>
            <v-text-field
              rounded
              solo
              dense
              flat
              v-model="form['card_name']"
              background-color="secondary"
              placeholder="Enter card holder name"
              :rules="validate.required('card holder name')"
            ></v-text-field>
            <label class="capitalize text-12">card number</label>
            <v-text-field
              rounded
              solo
              dense
              flat
              v-model="form['card_number']"
              background-color="secondary"
              placeholder="Enter card number"
              id="iban"
              @input="card_number"
              maxlength="19"
              :rules="validate.required('card number')"
            ></v-text-field>
            <v-row>
              <v-col>
                <label class="capitalize text-12">Month / Year</label>
                <v-text-field
                  rounded
                  solo
                  dense
                  flat
                  id="expiry_date"
                  @input="expiry_date"
                  maxlength="5"
                  v-model="form['expiry_date']"
                  background-color="secondary"
                  placeholder="Enter card expiry date"
                  :rules="validate.required('card expiry date')"
                ></v-text-field>
              </v-col>
              <v-col>
                <label class="capitalize text-12">CVV</label>
                <v-text-field
                  rounded
                  solo
                  dense
                  flat
                  v-model="form['cvv']"
                  background-color="secondary"
                  placeholder="Enter card cvv"
                  :rules="validate.required('card cvv')"
                  id="cvv"
                  maxlength="3"
                  @input="cvv"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="pt-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="$refs.form.validate() ? savePayment() : null"
              class="capitalize primary"
              rounded
              text
              large
              :loading="loading"
            >
              <small>save payment method</small>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormValidation from "../../utils/FormValidation";
import { mapGetters, mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "PaymentMethod",
  data() {
    return {
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      form: {
        card_type: "card",
      },
      loading: false,
    };
  },
  created() {
    this.form = Object.assign({}, this.card);
    let str = this.form.card_number.replace(/\s/g, "");
    str = str.replace(/\d(?=\d{4})/g, "*");
    this.form.card_number = str;
  },
  methods: {
    ...mapActions("payments", ["payment_methods"]),
    async savePayment() {
      if (this.form.card_number.length <= 16) {
        this.$toast.error("Invalid card number");
        return false;
      }
      this.form.user_id = this.user.id;
      this.loading = true;
      try {
        const response = await this.payment_methods(this.form);
        this.loading = false;
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    card_number() {
      let val = document.getElementById("iban");
      val.addEventListener("input", function (e) {
        e.target.value = e.target["value"]
          .replace(/[^\dA-Z]/g, "")
          .replace(/(.{4})/g, "$1 ")
          .trim();
      });
    },
    expiry_date() {
      let x = document.getElementById("expiry_date");
      x.value = x.value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\d\/]/g, "");
      this.form.expiry_date = x.value;
    },
    cvv() {
      let x = document.getElementById("cvv");
      x.addEventListener("input", function (e) {
        e.target.value = e.target["value"].replace(/[^\dA-Z]/g, "").trim();
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("payments", ["card"]),
  },
};
</script>

<style scoped></style>
